import Web3 from "web3";
import { minABI } from "./abi";
import factoryAbi from "../abi/saleFactoryAbi";
import saleAbi from "../abi/saleAbi";

// BNB mainnet ABI
import bsc_mainnet_busd_abi from "../abi/privateSaleABI/BNB/mainnet/busd_abi";
import bsc_mainnet_usdc_abi from "../abi/privateSaleABI/BNB/mainnet/usdc_abi";
import bsc_mainnet_usdt_abi from "../abi/privateSaleABI/BNB/mainnet/usdt_abi";
// BNB testnet ABI
import bsc_testnet_busd_abi from "../abi/privateSaleABI/BNB/testnet/testnet_busd_abi";
import bsc_testnet_usdc_abi from "../abi/privateSaleABI/BNB/testnet/testnet_usdc_abi";
import bsc_testnet_usdt_abi from "../abi/privateSaleABI/BNB/testnet/testnet_usdt_abi";

// ETH mainnet ABI
import eth_mainnet_busd_abi from "../abi/privateSaleABI/ETH/mainnet/busd_abi";
import eth_mainnet_usdc_abi from "../abi/privateSaleABI/ETH/mainnet/usdc_abi";
import eth_mainnet_usdt_abi from "../abi/privateSaleABI/ETH/mainnet/usdt_abi";

// ETH testnet ABI
import eth_testnet_busd_abi from "../abi/privateSaleABI/ETH/testnet/testnet_busd_abi";
import eth_testnet_usdc_abi from "../abi/privateSaleABI/ETH/testnet/testnet_usdc_abi";
import eth_testnet_usdt_abi from "../abi/privateSaleABI/ETH/testnet/testnet_usdt_abi";

import moment from "moment";
import { ethers, BigNumber } from "ethers";

export const getNativeBalance = async (rpcUrl, account) => {
  const web3 = new Web3(rpcUrl[0]);
  try {
    const native = await web3.eth.getBalance(account);
    const res = web3.utils.fromWei(native);
    return res;
  } catch (err) {
    return -1;
  }
};

export const getTokenBalance = async (
  rpcUrl,
  account,
  tokenAddress,
  tokenDeci
) => {
  const web3 = new Web3(rpcUrl[0]);
  try {
    const contract = new web3.eth.Contract(minABI, tokenAddress);
    const token = await contract.methods.balanceOf(account).call();
    // const res = web3.utils.fromWei(token)
    const res = token / 10 ** tokenDeci;
    return res;
  } catch (err) {
    return -1;
  }
};
export const getWalletBalance = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let accounts = await provider.send("eth_requestAccounts", []);
    const balance = await provider.getBalance(accounts[0]);
    return ethers.utils.formatEther(balance);
  } catch (err) {
    return -1;
  }
};

export const createPrivateSale = async (
  _provider,
  _balance,
  _address,
  _account,
  _saleName,
  _usingWhitelist,
  _softCap,
  _hardCap,
  _startTime,
  _endTime,
  _minBuy,
  _maxBuy,
  _firstRelease,
  _eachCycle,
  _duration,
  _currency_contract_address,
  _admin_wallet_address,
  _selectedCurrencyAddress
) => {
  _softCap = BigNumber.from(ethers.utils.parseEther(_softCap));

  _hardCap = BigNumber.from(ethers.utils.parseEther(_hardCap));

  _minBuy = BigNumber.from(ethers.utils.parseEther(_minBuy));

  _maxBuy = BigNumber.from(ethers.utils.parseEther(_maxBuy));

  const web3 = new Web3(_provider);
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const current_network = await provider.getNetwork();
    let factory_contract_address = "";
    switch (current_network.chainId) {
      case 1:
        factory_contract_address =
          process.env.PRIVATE_SALE_ETH_CONTRACT_ADDRESS;
        break;
      case 5:
        factory_contract_address =
          process.env.PRIVATE_SALE_GOERLI_CONTRACT_ADDRESS;
        break;
      case 56:
        factory_contract_address =
          process.env.PRIVATE_SALE_BSC_CONTRACT_ADDRESS;
        break;
      case 97:
        factory_contract_address =
          process.env.PRIVATE_SALE_BSC_TESTNET_CONTRACT_ADDRESS;
        break;

      default:
        return {
          status: 0,
          message: "This network doesn't support please switch the network",
          data: null,
        };
    }
    const factory_contract = new web3.eth.Contract(
      factoryAbi,
      factory_contract_address
    );
    const adminPlatformCost = await factory_contract.methods
      .getDeployCost()
      .call();
    const valueInEther = ethers.utils.formatEther(adminPlatformCost);
    if (_balance <= valueInEther) {
      return {
        status: 0,
        message: "Insufficient wallet balance.",
        data: null,
      };
    }
    const contract = new web3.eth.Contract(
      factoryAbi,
      factory_contract_address
    );
    const tx = await contract.methods
      .createPrivateSale(
        _saleName, // private sale name
        _usingWhitelist == "disable" ? false : true, // whitelist status
        [_softCap, _hardCap],
        [_startTime, _endTime],
        [_minBuy, _maxBuy],
        _selectedCurrencyAddress,
        [_firstRelease, _eachCycle],
        _duration, // cycle duration
        _admin_wallet_address
      )
      .send({ from: _account[0], value: Number(adminPlatformCost) });

    tx.events.privateSaleDeployed["adminPlatformCost"] = adminPlatformCost;
    return {
      status: 1,
      message: "Data found",
      data: tx.events.privateSaleDeployed,
    };
  } catch (err) {
    console.log("err", err);
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};

export const userInvest = async (
  _provider,
  _saleAddress,
  _account,
  _amount,
  _presale_currency
) => {
  const web3 = new Web3(_provider);

  let currency_contract_address;
  let currency_contract_abi;
  let currency_decimals;

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const network = await provider.getNetwork()

  if(network.name == "bnbt" || network.name == 'bnb'){
    if(network.name == 'bnb'){
      switch (_presale_currency) {
        case "USDC":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_MAINNET_USDC_ADDRESS;
          currency_contract_abi = bsc_mainnet_usdc_abi;
          currency_decimals = 18
          break;
        case "BUSD":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_MAINNET_BUSD_ADDRESS;
          currency_contract_abi = bsc_mainnet_busd_abi
          currency_decimals = 18
          break;
        case "USDT":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_MAINNET_USDT_ADDRESS;
          currency_contract_abi = bsc_mainnet_usdt_abi
          currency_decimals = 6
          break;
        default:
          break;
      }
    }else{
      switch (_presale_currency) {
        case "USDC":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_TESTNET_USDC_ADDRESS;
          currency_contract_abi = bsc_testnet_usdc_abi;
          currency_decimals = 18
          break;
        case "BUSD":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_TESTNET_BUSD_ADDRESS;
          currency_contract_abi = bsc_testnet_busd_abi
          currency_decimals = 18
          break;
        case "USDT":
          currency_contract_address = process.env.PRIVATE_SALE_BNB_TESTNET_USDT_ADDRESS;
          currency_contract_abi = bsc_testnet_usdt_abi
          currency_decimals = 6
          break;
        default:
          break;
      }
    }
  }else if(network.name == "homestead" || network.name == 'goerli'){
    if(network.name == 'homestead'){
      switch (_presale_currency) {
        case "USDC":
          currency_contract_address = process.env.PRIVATE_SALE_ETH_MAINNET_USDC_ADDRESS;
          currency_contract_abi = eth_mainnet_usdc_abi;
          currency_decimals = 18
          break;
        case "USDT":
          currency_contract_address = process.env.PRIVATE_SALE_ETH_MAINNET_USDT_ADDRESS;
          currency_contract_abi = eth_mainnet_usdt_abi
          currency_decimals = 6
          break;
        default:
          break;
      }
    }else{
      switch (_presale_currency) {
        case "USDC":
          currency_contract_address = process.env.PRIVATE_SALE_ETH_TESTNET_USDC_ADDRESS;
          currency_contract_abi = eth_testnet_usdc_abi;
          currency_decimals = 18
          break;
        case "USDT":
          currency_contract_address = process.env.PRIVATE_SALE_ETH_TESTNET_USDT_ADDRESS;
          currency_contract_abi = eth_testnet_usdt_abi
          currency_decimals = 6
          break;
        default:
          break;
      }
    }
  }

  if (_presale_currency == "BNB" || _presale_currency == "ETH") {
    try {
      const contract = new web3.eth.Contract(saleAbi, _saleAddress);
      const tx = await contract.methods
        .invest(_amount)
        .send({ from: _account, value: BigNumber.from(_amount._hex) }); // pass the admin fee value in static value
      return {
        status: 1,
        message: "Transaction Successful",
        data: tx,
      };
    } catch (err) {
      console.log('err', err)
      if (err.code === 4001) {
        return {
          status: 0,
          message: "Transaction rejected",
          data: null,
        };
      } else {
        return {
          status: 0,
          message: err.message,
          data: null,
        };
      }
    }
  } else {
    try {

  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  // const signer = provider.getSigner();
  // const usdcContract = new ethers.Contract('0x1119C3a415b62127CB8Ef653a77a717839414310', testnet_usdt_abi, signer); // replace with the USDC contract address
  // const approveAmount = ethers.utils.parseUnits('10', 6); // approve 10 USDC, which is 10 * 10^6 units
  // const spender = '0x48bAebae8147a91dDE411aCdaBb4B14cA4063098'; // replace with the address of the contract or account you want to allow to spend your USDC
  
  // usdcContract.approve(spender, approveAmount)
  //   .then((tx) => {
  //     console.log('Transaction hash:', tx.hash);
  //     return tx.wait();
  //   })
  //   .then((receipt) => {
  //     console.log('Transaction confirmed in block', receipt.blockNumber);
  //   })
  //   .catch((error) => {
  //     console.error('Transaction failed:', error);
  //   });
  

  // connect wallet
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // create contract
      const contract = new ethers.Contract(_saleAddress, saleAbi, provider);
      const token_contract_address = new ethers.Contract(currency_contract_address.trim(), currency_contract_abi, signer);
      // get approval
      // const amount = ethers.utils.parseUnits(_amount.toString(), currency_decimals); // Replace with the amount of token_contract_address tokens to approve
      const amount = ethers.utils.parseUnits('10', currency_decimals); // Replace with the amount of token_contract_address tokens to approve

      const approvalTx = await token_contract_address.approve(contract.address, amount);
      await approvalTx.wait();
      // investment transaction
      const investmentTx = new web3.eth.Contract(saleAbi, _saleAddress);
      const tx = await investmentTx.methods
        .invest(_amount)
        .send({ from: _account, value: BigNumber.from(_amount._hex) }); // pass the admin fee value in static value
        return {
          status: 1,
          message: "Transaction Successful",
          data: tx,
        };
    } catch (error) {
      console.log('error', error)
      if (error.code === 4001) {
        return {
          status: 0,
          message: "Transaction rejected",
          data: null,
        };
      } else {
        return {
          status: 0,
          message: error.message,
          data: null,
        };
      }
    }
  }
};

export const userFinalize = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods.finishSale().send({ from: _account[0] });

    if (tx) {
      return {
        status: 1,
        message: "Finalize pool successfully",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};

export const userCancel = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods.cancelSale().send({ from: _account });

    if (tx) {
      return {
        status: 1,
        message: "Cancel pool successfully",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};

export const userClaimReFund = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods
      .claimRefund()
      .send({ from: _account, value: 0 });

    if (tx) {
      return {
        status: 1,
        message: "User claim Refund successfully",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const onUserEmergencyWithdrawFund = async (
  _provider,
  _saleAddress,
  _account,
  amount
) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods
      .withdrawBeforePrivateSaleFinalize(ethers.utils.parseUnits(amount, 18))
      .send({ from: _account, value: 0 });

    if (tx) {
      return {
        status: 1,
        message: "Withdrawn successfully",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};

export const ClaimFund = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods
      .claimFund()
      .send({ from: _account, value: 0 });
    // .send({ from: _account, value: '1000000000000000' });

    if (tx) {
      return {
        status: 1,
        message: "Claim pool successfully",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const enableWhiteList = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods
      .enableWhitelist()
      .send({ from: _account, value: 0 });
    if (tx) {
      return {
        status: 1,
        message: "White list is enable",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const disableWhiteList = async (_provider, _saleAddress, _account) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);
    const tx = await contract.methods
      .disableWhitelist()
      .send({ from: _account, value: 0 });
    if (tx) {
      return {
        status: 1,
        message: "White list is disabled",
        data: tx,
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const addWhiteList = async (
  _provider,
  _saleAddress,
  _account,
  whitelistArray
) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);

    // ['0xED61c76E027BD9Bdad6812539A4ae53e8cb9A4DC']
    const tx = await contract.methods
      .addWhitelisters(whitelistArray)
      .send({ from: _account, value: 0 });
    if (tx) {
      return { status: 1, message: "Whitelist added successfully", data: tx };
    } else {
      return { status: 0, message: "Whitelist not added", data: null };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const removeWhiteList = async (
  _provider,
  _saleAddress,
  _account,
  whitelistArray
) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);

    // ['0xED61c76E027BD9Bdad6812539A4ae53e8cb9A4DC']
    const tx = await contract.methods
      .removeWhitelisters(whitelistArray)
      .send({ from: _account, value: 0 });
    if (tx) {
      return { status: 1, message: "Whitelist remove successfully", data: tx };
    } else {
      return { status: 0, message: "Whitelist not removed", data: null };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const settingTimeWhitelist = async (
  _provider,
  _saleAddress,
  _account,
  _time
) => {
  const web3 = new Web3(_provider);
  try {
    const contract = new web3.eth.Contract(saleAbi, _saleAddress);

    // ['0xED61c76E027BD9Bdad6812539A4ae53e8cb9A4DC']
    const tx = await contract.methods
      .setEndWhitelistTime(_time)
      .send({ from: _account, value: 0 });
    if (tx) {
      return {
        status: 1,
        message: "Whitelist timer set successfully",
        data: tx,
      };
    } else {
      return { status: 0, message: "Whitelist timer not set", data: null };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        status: 0,
        message: "Transaction rejected",
        data: null,
      };
    } else {
      return {
        status: 0,
        message: err.message,
        data: null,
      };
    }
  }
};
export const addTokenToMetamask = async (token_data) => {
  if (window.ethereum) {
    ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            name: token_data[0]?.name,
            address: token_data[0]?.contract_address,
            symbol: token_data[0]?.symbol,
            decimals: token_data[0]?.decimals,
            // image: tokenImage,
          },
        },
      })
      .then((success) => {
        if (success) {
        } else {
          console.error("Failed to add token to Metamask.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
  }
};
export const addToTrust = async (token_data) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const ethereumProvider = window.ethereum;
      if (ethereumProvider.isTrust) {
        // Check if the provider is Trust Wallet
        ethereumProvider
          .request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                name: token_data[0]?.name,
                address: token_data[0]?.contract_address,
                symbol: token_data[0]?.symbol,
                decimals: token_data[0]?.decimals,
              },
            },
          })
          .then((success) => {
            if (success) {
            } else {
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
      }
    } else {
    }
  } catch (error) {}
};
export const addSingleTokenToMetamask = async (token_data) => {
  if (window.ethereum) {
    ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            name: token_data?.name,
            address: token_data?.contract_address,
            symbol: token_data?.symbol,
            decimals: token_data?.decimals,
            // image: tokenImage,
          },
        },
      })
      .then((success) => {
        if (success) {
        } else {
          console.error("Failed to add token to Metamask.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
  }
};
export const addSingleToTrust = async (token_data) => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const ethereumProvider = window.ethereum;
      if (ethereumProvider.isTrust) {
        // Check if the provider is Trust Wallet
        ethereumProvider
          .request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                name: token_data?.name,
                address: token_data?.contract_address,
                symbol: token_data?.symbol,
                decimals: token_data?.decimals,
              },
            },
          })
          .then((success) => {
            if (success) {
            } else {
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
      }
    } else {
    }
  } catch (error) {}
};

async function goerliPrivateSale(params) {
  return {
    status: 0,
    message: "Transaction rejected",
    data: null,
  };
}
async function bscTestnetPrivateSale(
  _provider,
  _balance,
  _address,
  _account,
  _saleName,
  _usingWhitelist,
  _softCap,
  _hardCap,
  _startTime,
  _endTime,
  _minBuy,
  _maxBuy,
  _firstRelease,
  _eachCycle,
  _duration,
  _currency_contract_address,
  _admin_wallet_address
) {
  try {
    const web3 = new Web3(_provider);
    const factory_contract = new web3.eth.Contract(
      factoryAbi,
      process.env.PRIVATE_SALE_BSC_TESTNET_CONTRACT_ADDRESS
    );
    const adminPlatformCost = await factory_contract.methods
      .getDeployCost()
      .call();
    const valueInEther = ethers.utils.formatEther(adminPlatformCost);
    if (_balance <= valueInEther) {
      return {
        status: 0,
        message: "Insufficient wallet balance.",
        data: null,
      };
    }
    const contract = new web3.eth.Contract(factoryAbi, _address);
    const tx = await contract.methods
      .createPrivateSale(
        _saleName, // private sale name
        _usingWhitelist == "disable" ? false : true, // whitelist status
        [_softCap, _hardCap],
        [_startTime, _endTime],
        [_minBuy, _maxBuy],
        _currency_contract_address,
        [_firstRelease, _eachCycle],
        _duration, // cycle duration
        _admin_wallet_address
      )
      .send({ from: _account[0], value: Number(adminPlatformCost) });

    tx.events.privateSaleDeployed["adminPlatformCost"] = adminPlatformCost;
    return {
      status: 1,
      message: "Data found",
      data: tx.events.privateSaleDeployed,
    };
  } catch (error) {
    console.log("error", error);
  }
}
