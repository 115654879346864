export default [
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
          }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": false,
              "internalType": "address",
              "name": "privateSale",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "state",
              "type": "uint256"
          }
      ],
      "name": "privateSaleChanged",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": false,
              "internalType": "address",
              "name": "privateSale",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "address",
              "name": "deployer",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "address",
              "name": "tokenPayment",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "startTime",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "endTime",
              "type": "uint256"
          }
      ],
      "name": "privateSaleDeployed",
      "type": "event"
  },
  {
      "inputs": [],
      "name": "adminWallet",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "_saleName",
              "type": "string"
          },
          {
              "internalType": "bool",
              "name": "_usingWhitelist",
              "type": "bool"
          },
          {
              "internalType": "uint256[2]",
              "name": "_caps",
              "type": "uint256[2]"
          },
          {
              "internalType": "uint256[2]",
              "name": "_times",
              "type": "uint256[2]"
          },
          {
              "internalType": "uint256[2]",
              "name": "_limits",
              "type": "uint256[2]"
          },
          {
              "internalType": "address",
              "name": "_token",
              "type": "address"
          },
          {
              "internalType": "uint256[2]",
              "name": "percents",
              "type": "uint256[2]"
          },
          {
              "internalType": "uint256",
              "name": "delayDays",
              "type": "uint256"
          },
          {
              "internalType": "address",
              "name": "_adminAddress",
              "type": "address"
          }
      ],
      "name": "createPrivateSale",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "deployCost",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getAdminWallet",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getDeployCost",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "owner",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "name": "privateSaleByAddress",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "name": "privateSaleById",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
          }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "newWallet",
              "type": "address"
          }
      ],
      "name": "updateAdminWallet",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          }
      ],
      "name": "updateDeployCost",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
  }
]